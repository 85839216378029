.main {
  @apply h-screen  bg-gray-100 flex flex-col justify-between;
  background-image: url("/assets/bgs/hack-cover.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  padding-bottom: 30px;
}

.sectionHead {
  @apply text-[#5D5FEF] font-sans  text-[22px] mr-5  p-2;
}
