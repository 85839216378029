// @tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter-Thin";
  src: local("Inter Thin"),
    url("../assets/fonts/Inter/Inter-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Regular";
  src: local("Inter Regular"),
    url("../assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Light";
  src: local("Inter Light"),
    url("../assets/fonts/Inter/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Bold";
  src: local("Inter Bold"),
    url("../assets/fonts/Inter/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Medium";
  src: local("Inter Medium"),
    url("../assets/fonts/Inter/Inter-Medium.ttf") format("truetype");
}

// Poppins font

@font-face {
  font-family: "Poppins-Thin";
  src: local("Poppins Thin"),
    url("../assets/fonts/Poppins/Poppins-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins Regular"),
    url("../assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins Light"),
    url("../assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins Bold"),
    url("../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins Medium"),
    url("../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

// Roboto font

@font-face {
  font-family: "Roboto-Thin";
  src: local("Roboto Thin"),
    url("../assets/fonts/Roboto/Roboto-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto Regular"),
    url("../assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Light";
  src: local("Roboto Light"),
    url("../assets/fonts/Roboto/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto Bold"),
    url("../assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto Medium"),
    url("../assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
}

.p-color {
  color: blue;
}
.text-faded {
  @apply text-gray-500;
}

.dashboard-cover {
  background-image: url("../assets/bgs/hack-cover.jpg");
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Inter-Regular", "Helvetica Neue", sans-serif;
}

.ps-active {
  @apply bg-gray-100;
}

.inner-nav {
  button[data-active] {
    @apply text-h-black;
  }
}

.chakra-modal__content {
  margin: 20px;
}

.group-form-control {
  .chakra-form__label {
    @apply text-muted;
    font-size: small;
    margin-bottom: 2px;
  }
}
