.timer {
  @apply flex text-white gap-4 mt-2 md:mt-4 items-end;
}

.title {
  @apply text-lg sm:text-xl md:text-2xl lg:text-2xl;
}

.value {
  @apply text-3xl sm:text-4xl md:text-5xl lg:text-8xl;
}

.divider {
  @extend .value;
  @apply mb-3;
}
